.AddContactForm {
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  background: #3a3846;
  color: white;
  grid-column-start: 4;
  grid-column-end: 7;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.AddContactForm a{
  color: rgb(163, 161, 189);
  font-weight: bold;
}

#comments {
  width: 15em;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .AddContactForm {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
