@media (min-width: 961px) {
  .saved-job-list{
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 10px;
    height: 500px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}

@media screen and (max-width: 960px) {
  .saved-job-list{
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 10px;
    height: 400px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .saved-job-list{
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 10px;
    height: 400px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}