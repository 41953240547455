.LoginForm {
  grid-column-start: 1;
  grid-column-end: 9;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}


@media screen and (max-width: 480px) {
  .LoginForm {
    grid-column-start: 1;
    grid-column-end: 13; 
  }

}