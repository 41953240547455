.header {
  max-width: 100vw;
  background: #3a3846;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  height: 75px;
  margin-left: 0%;
  position: relative;
}
.user-cog{
  color: white;
}

.fish{
  display: inline-block;
}

.app-name{
  display: inline-block;
}

.logo {
  grid-column-start: 1;
  grid-column-end: 4;
  text-decoration: none;
  margin: auto auto auto 20px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
}

.logo > a > img {
  height: 50px;
  width: 50px;
}

.nav-links {
  grid-column-start: 10;
  grid-column-end: 13;
  font-family: 'Montserrat', sans-serif;
  margin: auto 20px auto auto;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: center;
}


.username {
  grid-column-start: 6;
  grid-column-end: 8;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin: auto;
  text-align: center;
  padding: 5px;
}

.nav-links a {
  text-decoration: none;
  display: block;
  text-align: right;
  padding-right: 15px;
  padding-top: 7.5px;
  font-size: 16px;
  margin: 5px;
}


@media screen and (max-width: 870px) {
  .header {
    grid-template-columns: 1fr 1fr 1fr;
    height: 75px;
  }

  .username {
    grid-column-start: 2;
    grid-column-end: 3;
    margin: auto;
    text-align: center;
    color: white;
  }

  .nav-links {
    grid-column-start: 3;
    grid-column-end: 4;
    margin: auto;
    text-align: center;
  }

  .logo {
    grid-column-start: 0;
    grid-column-end: 2;
    margin: auto auto auto 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }

  .nav-links a{
    display: block;
    text-align: right;
    padding-right: 15px;
    padding-top: 10px;
    font-size: 13px;
    margin: 5px;
  }
}

@media screen and (max-width: 400px) {
  .logo {
    font-size: 16px;
    margin-left: 10px;
  }

  .nav-links  a{
    display: block;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
    margin: 5px;
  }
}