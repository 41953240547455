.ProfileRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.title {
  grid-column-start: 1;
  grid-column-end: 9;
  text-align: center;
}
