@media screen and (min-width: 960px) {
  .events-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
}

  .results-container {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 18px;
    height: 500px;
    position: relative;
    text-align: center;
  }
  
  #go-back {
    position: absolute;
    left: 0;
    top: 0;
    color: #3a3846;
  }
  
  #go-back:hover {
    color: #fc766a;
  }
  
  .results > h2 {
    text-align: center;
  }
  
  .results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}

  @media screen and (max-width: 960px) {
    .events-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }  
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #3a3846;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }

  @media screen and (max-width: 480px) {
    .events-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #3a3846;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }