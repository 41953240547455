.notification-header {
  text-align: center;
}

.notifications-box{
  height: 350px;
}

.notifications {
  width: 80%;
  margin: 0 auto;
  line-height: 1.5rem;
}

.notification{
  background: rgb(238, 238, 238);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.notification a{
  color: rgb(89, 84, 153);
  font-weight: bold;
}

.notification-button{
  width: 50px;
  background: rgb(101, 101, 136);
  color: white;
  border-radius: 10px;
  border: none;
  height: 2em;
  margin: auto 10px;
}

.notification-button:hover {
  background: grey;
}

.notification-button:focus {
  outline: none;
}