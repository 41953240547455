.resources-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.resourcesBack {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.resourcesBack > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -170px;
  left: 10%;
  z-index: -1;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}