@media (min-width: 761px) {
  .app-description {
    border: 1px black solid;
    padding: 5%;
    min-height: 350px;
    border-radius: 10px;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description > h1{
    font-size: 26px;
    text-align: left;
  }
  
  .app-description > h2{
    font-size: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  
  .app-description > p {
    margin-top: 1.5em; 
    line-height: 150%;
  }
}

@media (min-width: 320px) and (max-width: 760px) {
  .app-description {
    border: 1px black solid;
    padding: 5%;
    min-height: 350px;
    margin: 10px auto;
    border-radius: 10px;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description > h1{
    font-size: 26px;
    text-align: left;
  }
  
  .app-description > h2{
    font-size: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  
  .app-description > p {
    margin-top: 1.5em; 
    line-height: 150%;
  }
}


