.DashCompanySummary > h4 {
  text-align: center;
}

.options > ul > li {
  list-style: none;
  width: 100%;
}

.ul {
  padding-left: 0;
  width: 95%;
  margin: auto;
  height: 100%;
}

.viewAll {
  text-align: center;
}