.saved-company-list {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    height: 500px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow-y: auto;
}

.savedCompanyFilterControls{
    width: 90%;
    text-align: right;
    margin: 15px;
}

@media screen and (max-width: 960px) {
    .saved-company-list {
        grid-column-start: 1;
        grid-column-end: 9;
    }
}