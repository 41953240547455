.saved-jobs-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.form {
  grid-column-start: 6;
  grid-column-end: 9;
}

.savedJobBackgroundImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.savedJobBackgroundImage > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -100px;
  left: 15%;
  z-index: -1;
}

@media (min-width: 641px) and (max-width: 960px) {
  .form {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .title h2{
    background: rgb(238, 238, 238);
    border-radius: 10px;
    width: 40%;
    margin: 15px auto;
  }
}

@media (min-width: 320px) and (max-width: 640px) {
  .form {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .sideNavContainer {
    display: none;
  }

  .title h2{
    background: rgb(238, 238, 238);
    border-radius: 10px;
    width: 50%;
    margin: 15px auto;
  }
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}

