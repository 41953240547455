.contact-box {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  padding: 1em;
  border-radius: 10px;
  margin: 0.75em auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-contact-form {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
}

.edit-contact-form button{
  margin: 5px;
}

#connected-Input {
  width: 15em;
  border-radius: 10px;
}

.connection-status{
  border: 1px solid black;
  padding: 5px;
  margin: 0px auto;
  color: white;
}