.SideNav {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0.5em;
  border-radius: 10px;
  width: 200px;
  min-height: 250px;
  height: 100%;
  max-height: 100%;
  text-align: left;
  background: #3a3846;
  color: white;
}

.link {
  width: 100%;
  height: 40px;
  margin: auto;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  padding: 0.2em;
}

.link > a {
  font-size: 1em;
}


.link:active, .link:hover {
  transition: all 0.3s ease-in;
  background: #676572;
}

@media screen and (max-width: 960px) {
  .SideNav {
    grid-column-start: 1;
    grid-column-end: 9;
  }


}

@media screen and (max-width: 480px) {
  .SideNav {
    grid-column-start: 1;
    grid-column-end: 9;
    width: 100%;
    margin-bottom: 1em;
    line-height: 168%;
    text-align: center;
  }


}


