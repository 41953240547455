.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.person-card {
  display: inline-block;
  background: #3a3846;
  width: 175px;
  color: white;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 10px;
}

.person-card:hover {
  background: #504e5a;
  transition: 0.3s ease-in;
}

.person-contact-info {
  margin-left: 60%;
}