.resource-card {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  margin-bottom: 10px;
  padding: 0.5em;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  margin-bottom: 1em;
}

#type-Input {
  width: 15em;
  border-radius: 10px;
}

.card-description{
  overflow-wrap: break-word;
}

.savedResourceFilterControls{
  margin: 15px;
}

#saved-resource-search{
  margin-right: 0px;
}