@media screen and (min-width: 960px) {
  .EditProfileForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3b3a50;
    color: #eeebd9;
    grid-column-start: 6;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}

@media screen and (max-width: 960px) {
  .EditProfileForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3b3a50;
    color: #eeebd9;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}

