.resources-list {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 9;
  height: 500px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  overflow-y: auto;
}

.savedResourceFilterControls {
  width: 90%;
  text-align: right;
}

@media screen and (max-width: 960px) {
  .resources-list {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
