.DashJobSummary > h4 {
  text-align: center;
}

.options > ul > li {
  list-style: none;
  width: 100%;
}

.ul {
  margin: auto;
  height: 100%;
  background: rgb(238, 238, 238);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.ul a{
  color: rgb(89, 84, 153);
  font-weight: bold;
}

.viewAll {
  text-align: center;
  margin: 15px auto
}
