@import url(https://fonts.googleapis.com/css?family=Barlow|Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
* {
  box-sizing: border-box;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
}

p {font-size: 1em}

a {
  text-decoration: none;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(238, 238, 238);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --font-size: calc(10px + 1vmin);
}

.error-message{
  color: red;
  background: white;
}

@media (min-width: 761px) {
  .app-description {
    border: 1px black solid;
    padding: 5%;
    min-height: 350px;
    border-radius: 10px;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description > h1{
    font-size: 26px;
    text-align: left;
  }
  
  .app-description > h2{
    font-size: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  
  .app-description > p {
    margin-top: 1.5em; 
    line-height: 150%;
  }
}

@media (min-width: 320px) and (max-width: 760px) {
  .app-description {
    border: 1px black solid;
    padding: 5%;
    min-height: 350px;
    margin: 10px auto;
    border-radius: 10px;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description > h1{
    font-size: 26px;
    text-align: left;
  }
  
  .app-description > h2{
    font-size: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  
  .app-description > p {
    margin-top: 1.5em; 
    line-height: 150%;
  }
}





.Input {
  border-radius: 10px;
  width: 15em;
  height: 2em;
}

.Input:focus {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  outline: none;
}
.Button { 
  background: rgb(212, 212, 212);
  border: none;
  color: black;
  border-radius: 10px;
  margin: 5px;
  width: 10em;
  height: 2em;
  font-size: 14px;
}

.Button:hover {
  background: rgb(141, 141, 167);
}

.Button:focus {
  outline: none;
}
@media (min-width: 761px) {
  .registration-page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    background-attachment: scroll;
    position: relative;
  }
  
  .registration-image > img {
    float: left;
    height: 320px;
    width: 320px;
    position: absolute;
    left: -35%;
    z-index: -1;
  }
  
  .form-registration {
    grid-column-start: 5;
    grid-column-end: 9;
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    height: 100%;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description {
    grid-column-start: 0;
    grid-column-end: 4;
    height: 100%;
    line-height: 2rem;
    min-width: 300px;
  }
  
  .error {
    color: red;
  }
}

@media (min-width: 320px) and (max-width: 760px) {
  .registration-page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
    background-attachment: scroll;
    position: relative;
  }
  
  .registration-image > img {
    float: left;
    height: 320px;
    width: 320px;
    position: absolute;
    left: -35%;
    z-index: -1;
  }
  
  .form-registration {
    grid-column-start: 2;
    grid-column-end: 8;
    border: 1px black solid;
    min-width: 95%;
    margin: 5px auto 25px auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    height: 100%;
    background: #3a3846;
    color: white;
    box-shadow: 0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .app-description {
    grid-column-start: 2;
    grid-column-end: 8;
    height: 25em;
  }
  
  .error {
    color: red;
  }
}



@media screen and (min-width: 961px) {
  .App {
    margin: 0 auto;
    max-width: 100vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 10% 1fr 10%;
    grid-gap: 16px;
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    cursor: pointer;
  }
  
  .header {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  
  .footer {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  
  main {
    grid-column-start: 3;
    grid-column-end: 11;
  
  }
}

@media screen and (max-width: 960px) {
  .App {
    margin: 0 auto;
    max-width: 100vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 10% 1fr 10%;
    grid-gap: 16px;
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  .header {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  
  .footer {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  
  main {
    grid-column-start: 2;
    grid-column-end: 12;
  
  }
}

@media screen and (max-width: 480px) {
  .App {
    margin: 0 auto;
    max-width: 100vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 10% 1fr 10%;
    grid-gap: 16px;
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
  }
  
  .header {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  
  .footer {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  
  main {
    grid-column-start: 1;
    grid-column-end: 13;
  
  }
}



.header {
  max-width: 100vw;
  background: #3a3846;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  height: 75px;
  margin-left: 0%;
  position: relative;
}
.user-cog{
  color: white;
}

.fish{
  display: inline-block;
}

.app-name{
  display: inline-block;
}

.logo {
  grid-column-start: 1;
  grid-column-end: 4;
  text-decoration: none;
  margin: auto auto auto 20px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
}

.logo > a > img {
  height: 50px;
  width: 50px;
}

.nav-links {
  grid-column-start: 10;
  grid-column-end: 13;
  font-family: 'Montserrat', sans-serif;
  margin: auto 20px auto auto;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: center;
}


.username {
  grid-column-start: 6;
  grid-column-end: 8;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin: auto;
  text-align: center;
  padding: 5px;
}

.nav-links a {
  text-decoration: none;
  display: block;
  text-align: right;
  padding-right: 15px;
  padding-top: 7.5px;
  font-size: 16px;
  margin: 5px;
}


@media screen and (max-width: 870px) {
  .header {
    grid-template-columns: 1fr 1fr 1fr;
    height: 75px;
  }

  .username {
    grid-column-start: 2;
    grid-column-end: 3;
    margin: auto;
    text-align: center;
    color: white;
  }

  .nav-links {
    grid-column-start: 3;
    grid-column-end: 4;
    margin: auto;
    text-align: center;
  }

  .logo {
    grid-column-start: 0;
    grid-column-end: 2;
    margin: auto auto auto 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }

  .nav-links a{
    display: block;
    text-align: right;
    padding-right: 15px;
    padding-top: 10px;
    font-size: 13px;
    margin: 5px;
  }
}

@media screen and (max-width: 400px) {
  .logo {
    font-size: 16px;
    margin-left: 10px;
  }

  .nav-links  a{
    display: block;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
    margin: 5px;
  }
}
.footer {
  max-width: 100vw;
  font-family: 'Montserrat', sans-serif;
  background: #3a3846;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  height: 100%;
  text-align: center;
}

.frontEnd {
  grid-column-start: 1;
  grid-column-end: 2;
  margin: auto;
  padding-left: 15px;
}

.backEnd {
  grid-column-start: 3;
  grid-column-end: 4;
  margin: auto;
  padding-right: 15px;
}

.contactUs {
  grid-column-start: 2;
  grid-column-end: 3;
  margin: auto;
}

@media screen and (max-width: 870px){
  .footer{
    height: 75px;
  }
}


.LoginForm {
  grid-column-start: 1;
  grid-column-end: 9;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}


@media screen and (max-width: 480px) {
  .LoginForm {
    grid-column-start: 1;
    grid-column-end: 13; 
  }

}
.LoginRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}
.login-image {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.login-image > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: 0;
  right: -10%;
  z-index: -1;
}

.new-user {
  grid-column-start: 3;
  grid-column-end: 7;
  text-align: center;
  color: #eeebd9;
}

@media screen and (max-width: 480px) {
  .new-user {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}
.dashboard-page { 
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}
.dashboard-image > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}

.title {
  grid-column-start: 1;
  grid-column-end: 9;
  text-align: center;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}

.SideNav {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0.5em;
  border-radius: 10px;
  width: 200px;
  min-height: 250px;
  height: 100%;
  max-height: 100%;
  text-align: left;
  background: #3a3846;
  color: white;
}

.link {
  width: 100%;
  height: 40px;
  margin: auto;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  padding: 0.2em;
}

.link > a {
  font-size: 1em;
}


.link:active, .link:hover {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background: #676572;
}

@media screen and (max-width: 960px) {
  .SideNav {
    grid-column-start: 1;
    grid-column-end: 9;
  }


}

@media screen and (max-width: 480px) {
  .SideNav {
    grid-column-start: 1;
    grid-column-end: 9;
    width: 100%;
    margin-bottom: 1em;
    line-height: 168%;
    text-align: center;
  }


}



.TopNav {
  grid-column-start: 1;
  grid-column-end: 9;
  width: 100%;
  min-width: 320px;
  height: 130px;
  border-radius: 10px;
  margin: 2.5% auto;
  background: #3a3846;
  color: white;
  line-height: 168%;
  text-align: left;
  padding: 10px;
}

.topLink {
  display: inline-block;
  padding-left: 10px;
  width: 50%;
  font-family: 'Montserrat', sans-serif;
}

.topLink:active, .topLink:hover {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background: #676572;
}

.topLink > a {
  font-size: 1em;
}

@media screen and (max-width: 380px) {
  .topLink {
    width: 50%;
    font-size: 14px;
  }
  .TopNav {
   min-width: 300px;
   margin: auto;
   width: 95%;
  }

}
.DashJobSummary > h4 {
  text-align: center;
}

.options > ul > li {
  list-style: none;
  width: 100%;
}

.ul {
  margin: auto;
  height: 100%;
  background: rgb(238, 238, 238);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.ul a{
  color: rgb(89, 84, 153);
  font-weight: bold;
}

.viewAll {
  text-align: center;
  margin: 15px auto
}

.DashResourceSummary > h4 {
  text-align: center;
}

.options > ul > li {
  list-style: none;
  width: 100%;
}

.ul {
  padding-left: 0;
  width: 95%;
  margin: auto;
  height: 100%;
}

.viewAll {
  text-align: center;
}
.DashCompanySummary > h4 {
  text-align: center;
}

.options > ul > li {
  list-style: none;
  width: 100%;
}

.ul {
  padding-left: 0;
  width: 95%;
  margin: auto;
  height: 100%;
}

.viewAll {
  text-align: center;
}
.DashContactSummary > h4 {
  text-align: center;
}

.options > ul > li {
  margin: 10px;
  list-style: none;
}

.ul {
  padding-left: 0;
  width: 95%;
  margin: auto;
  height: 100%;
}

.viewAll {
  text-align: center;
}
.DashEventSummary > h4 {
  text-align: center;
}

.options > ul > li {
  margin: 10px;
  list-style: none;
  width: 90%;
}
.notification-header {
  text-align: center;
}

.notifications-box{
  height: 350px;
}

.notifications {
  width: 80%;
  margin: 0 auto;
  line-height: 1.5rem;
}

.notification{
  background: rgb(238, 238, 238);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.notification a{
  color: rgb(89, 84, 153);
  font-weight: bold;
}

.notification-button{
  width: 50px;
  background: rgb(101, 101, 136);
  color: white;
  border-radius: 10px;
  border: none;
  height: 2em;
  margin: auto 10px;
}

.notification-button:hover {
  background: grey;
}

.notification-button:focus {
  outline: none;
}
.carousel__next-button, .carousel__back-button {
  background: rgb(141, 141, 167);
  border: none;
  color: white;
  height: 2.5em; 
}

.SummaryContainer{
  max-height: 350px;
  position: relative;
}

.carousel{
  max-height: 350px;
}

.summary-div{
  max-height: 350px;
}

.slideInner___2mfX9.carousel__inner-slide{
  height: 350px;
  padding-bottom: 0px;
}

.horizontalSlider___281Ls.carousel__slider.carousel__slider--horizontal{
  height: 350px;
  padding-bottom: 0px;
}

li.slide___3-Nqo.slideHorizontal___1NzNV.carousel__slide.carousel__slide--visible{
  height: 350px;
  padding-bottom: 0px;
} 

@media (min-width: 961px) {
  .SummaryContainer {
    grid-column-start: 4;
    grid-column-end: 9;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }

  .summary-div {
    height: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
  
  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(101, 101, 136);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
}

@media (min-width: 481px) and (max-width: 960px) {
  .sideNav {
    width: 200px;
  }
  .SummaryContainer {
    grid-column-start: 1;
    grid-column-end: 9;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }
  
  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(141, 141, 167);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  .SummaryContainer {
    grid-column-start: 2;
    grid-column-end: 8;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }

  .summary-div{
    width: 85%;
    margin: auto;
  }

  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(141, 141, 167);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}






.add-job-form {
  border: 1px black solid;
  grid-column-start: 4;
  grid-column-end: 7;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

@media screen and (max-width: 960px) {
  .add-job-form {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

.green{
  background: rgb(102, 96, 194);
}

.yellow{
  background: #349caa;
}

.red{
  background: #e46f65;
}

.job-status{
  color: white;
}

.job-box{
  background: #3a3846;
    color: white;
}

@media screen and (min-width: 960px) {
  .job-box {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}

@media screen and (max-width: 960px) {
  .job-box {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 80%;
    border: 1px black solid;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}

@media screen and (max-width: 480px) {
  .job-box {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}





@media (min-width: 961px) {
  .saved-job-list{
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 10px;
    height: 500px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}

@media screen and (max-width: 960px) {
  .saved-job-list{
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 10px;
    height: 400px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .saved-job-list{
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 10px;
    height: 400px;
    position: relative;
    text-align: center;
    overflow-y: auto;
  }
  
  .savedJobFilterControls {
    width: 90%;
    text-align: right;
    margin: 10px;
  }

  #saved-job-search{
    margin-right: 0px;
  }
  
  
  #savedJobFilterTitle {
    vertical-align: middle;
  }
  
  #statusInput {
    vertical-align: middle;
    margin-top: 0.5em;
    height: 20px;
    border-radius: 10px;
  }
  
  #savedJobFilterTitle {
    margin: 0.5em auto;
  }
  
  #addButton {
    margin-top: 0.5em;
    margin: auto;
  }
}
.saved-jobs-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.form {
  grid-column-start: 6;
  grid-column-end: 9;
}

.savedJobBackgroundImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.savedJobBackgroundImage > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -100px;
  left: 15%;
  z-index: -1;
}

@media (min-width: 641px) and (max-width: 960px) {
  .form {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .title h2{
    background: rgb(238, 238, 238);
    border-radius: 10px;
    width: 40%;
    margin: 15px auto;
  }
}

@media (min-width: 320px) and (max-width: 640px) {
  .form {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .sideNavContainer {
    display: none;
  }

  .title h2{
    background: rgb(238, 238, 238);
    border-radius: 10px;
    width: 50%;
    margin: 15px auto;
  }
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}


.AddEventForm {
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: white;
  grid-column-start: 4;
  grid-column-end: 7;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

@media screen and (max-width: 960px) {
  .AddEventForm {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
.event-box {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 1em;
    border-radius: 10px;
    background: #3a3846;
    color: white;
    margin-bottom: 1.5em;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
}

.event-box a{
    color: rgb(163, 161, 189);
    font-weight: bold;
}

.event-box button, .edit-event-form button{
   margin: 5px;
}

.edit-event-form {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1.5em;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
}

#description {
    width: 15em;
    border-radius: 10px;
}

#status-input {
    width: 15em;
    margin: 0 auto;
    border-radius: 10px;
    height: 2em;
}
.saved-event-list {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    height: 500px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow-y: auto;
}

.savedEventFilterControls { 
    width: 90%;
    text-align: right;
    margin: 10px;
}

#saved-event-search{
    margin-right: 0px;
}

@media screen and (max-width: 960px) {
    .saved-event-list {
        grid-column-start: 1;
        grid-column-end: 9;
    }
}
.SavedEventsRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.savedEventsBack {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.savedEventsBack > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -200px;
  right: 5%;
  z-index: -1;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}
@media screen and (min-width: 960px) {
  .AddCompanyForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    grid-column-start: 4;
    grid-column-end: 7;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  #state-input {
    margin: 0 auto;
    border-radius: 10px;
    width: 15em;
    height: 2em;
  }
}

@media screen and (max-width: 960px) {
  .AddCompanyForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3b3a50;
    color: #eeebd9;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  #state-input {
    margin: 0 auto;
    border-radius: 10px;
    width: 15em;
    height: 2em;
  }
}


.company-box {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-company-form {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-company-form button{
    margin: 10px 5px;
}
.saved-company-list {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    height: 500px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow-y: auto;
}

.savedCompanyFilterControls{
    width: 90%;
    text-align: right;
    margin: 15px;
}

@media screen and (max-width: 960px) {
    .saved-company-list {
        grid-column-start: 1;
        grid-column-end: 9;
    }
}
.SavedCompaniesRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.savedCompBack {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.savedCompBack > img {
  height: 200px;
  width: 200px;
  position: absolute;
  top: -170px;
  right: 0%;
  z-index: -1;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .job-card {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type save url";
    position: relative;
  }
  
  .job-card-expanded {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type url url"
                          "something save placeholder";
    position: relative;
  }
  
  .job-card-title {
    grid-area: title;
    text-align: left;
  }
  
  .job-card-location {
   grid-area: location;
   text-align: right;
  }
  
  .job-card-company {
    grid-area: company;
    text-align: left;
  }
  
  .job-card-type {
    grid-area: type;
    text-align: left;
  }
  
  .job-card-url {
    grid-area: url;
    text-align: right;
  }
  
  .job-card-apply {
    grid-area: apply;
    text-align: right;
  }
  
  .expandButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .collapseButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .save-button {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    position: relative;
  }
  
  #save-button {
    position: absolute;
    bottom: 0.5em;
    left:20%;
  }
  
  .collapseButton:hover {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
  .expandButton:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@media (min-width: 481px) and (max-width: 960px) {
  .job-card {
    width: 80%;
    border: 1px black solid;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type save url";
    position: relative;
  }
  
  .job-card-expanded {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type url url"
                          "something save placeholder";
    position: relative;
  }
  
  .job-card-title {
    grid-area: title;
    text-align: left;
  }
  
  .job-card-location {
   grid-area: location;
   text-align: right;
  }
  
  .job-card-company {
    grid-area: company;
    text-align: left;
  }
  
  .job-card-type {
    grid-area: type;
    text-align: left;
  }
  
  .job-card-url {
    grid-area: url;
    text-align: right;
  }
  
  .job-card-apply {
    grid-area: apply;
    text-align: right;
  }
  
  .expandButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .collapseButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .save-button {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    position: relative;
  }
  
  #save-button {
    position: absolute;
    bottom: 0.5em;
    left:35%;
  }
  
  .collapseButton:hover {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
  .expandButton:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@media (min-width: 961px) {
  .job-card {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type save url";
    position: relative;
  }
  
  .job-card-expanded {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: "title title location"
                          "company apply apply"
                          "type url url"
                          "something save placeholder";
    position: relative;
  }
  
  .job-card-title {
    grid-area: title;
    text-align: left;
  }
  
  .job-card-location {
   grid-area: location;
   text-align: right;
  }
  
  .job-card-company {
    grid-area: company;
    text-align: left;
  }
  
  .job-card-type {
    grid-area: type;
    text-align: left;
  }
  
  .job-card-url {
    grid-area: url;
    text-align: right;
  }
  
  .job-card-apply {
    grid-area: apply;
    text-align: right;
  }
  
  .expandButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .collapseButton {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
  
  .save-button {
    grid-area: save;
    position: relative;
  }
  
  #save-button {
    position: absolute;
    bottom: 0.5em;
    left:5%;
  }
  
  .collapseButton:hover {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
  .expandButton:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media screen and (min-width: 961px) {
  .job-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
  }
  
  .job-results-container {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 18px;
    height: 400px;
    position: relative;
    text-align: center;
  }

  .jobsRouteImage {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .jobsRouteImage > img {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -100px;
    right: -15%;
    z-index: -1;
  }
  
  #job-go-back {
    position: absolute;
    left: 0;
    top: 0;
    color:#3a3846;
  }
  
  #job-go-back:hover {
    color: #fc766a;
  }
  
  .job-results > h2 {
    text-align: center;
  }
  
  .job-results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}

@media screen and (max-width: 960px) {
  .job-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
  }

  .jobsRouteImage {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .jobsRouteImage > img {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -100px;
    right: -5%;
    z-index: -1;
  }
  
  .job-results-container {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 18px;
    height: 400px;
    position: relative;
    text-align: center;
  }

  .topNav {
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  #job-go-back {
    position: absolute;
    left: 0;
    top: 0;
    color: #3a3846;
  }
  
  #job-go-back:hover {
    color: #fc766a;
  }
  
  .job-results > h2 {
    text-align: center;
  }
  
  .job-results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}







.JobSearchForm {
  width: 320px;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: #eeebd9;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.jobSearchFormImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.jobSearchFormImage > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: -100px;
  left: -15%;
  z-index: -1;
}
.AddContactForm {
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  background: #3a3846;
  color: white;
  grid-column-start: 4;
  grid-column-end: 7;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.AddContactForm a{
  color: rgb(163, 161, 189);
  font-weight: bold;
}

#comments {
  width: 15em;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .AddContactForm {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

.SavedContactsRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.savedContBack {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.savedContBack > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -170px;
  left: 5%;
  z-index: -1;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}
.contact-box {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  padding: 1em;
  border-radius: 10px;
  margin: 0.75em auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-contact-form {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
}

.edit-contact-form button{
  margin: 5px;
}

#connected-Input {
  width: 15em;
  border-radius: 10px;
}

.connection-status{
  border: 1px solid black;
  padding: 5px;
  margin: 0px auto;
  color: white;
}
.saved-contact-list {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    height: 500px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow-y: auto;
}

.savedContactFilterControls {
    width: 90%;
    text-align: right; 
    margin: 10px;
}

#saved-contact-search{
    margin-right: 0px;
}

@media screen and (max-width: 960px) {
    .saved-contact-list {
        grid-column-start: 1;
        grid-column-end: 9;
    }
}
.ProfessionalSearchForm {
  width: 320px;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.option {
  color: black;
}

.findProfessionalsBackgroundImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.findProfessionalsBackgroundImage > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -100px;
  left: 5%;
  z-index: -1;
}
.contact-card {
  width: 80%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 0.75em auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  text-align: left;
  padding: 0.5em;
}

@media screen and (min-width: 960px) {
  .contacts-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
}

.findContactsRouteImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.findContactsRouteImage > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: -50px;
  left: 10%;
  z-index: -1;
}


  .results-container {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 18px;
    height: 400px;
    position: relative;
    text-align: center;
  }
  
  #go-back {
    position: absolute;
    left: 0;
    top: 0;
    color: #eeebd9;
  }
  
  #go-back:hover {
    color: #fc766a;
  }
  
  .results > h2 {
    text-align: center;
  }
  
  .results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}

  @media screen and (max-width: 960px) {
    .contacts-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }

  .findContactsRouteImage {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .findContactsRouteImage > img {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -50px;
    left: 10%;
    z-index: -1;
  }
  
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #eeebd9;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }

  @media screen and (max-width: 480px) {
    .contacts-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #eeebd9;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }
.resources-form {
  grid-column-start: 4;
  grid-column-end: 7;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  background: #3a3846;
    color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  height: 100%;
}

#resources-description-input {
  width: 15em;
  border-radius: 10px;
}

#resource-type-input {
  width: 15em;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .resources-form {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
.resources-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.resourcesBack {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.resourcesBack > img {
  height: 250px;
  width: 250px;
  position: absolute;
  top: -170px;
  left: 10%;
  z-index: -1;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}
.resources-list {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 9;
  height: 500px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  overflow-y: auto;
}

.savedResourceFilterControls {
  width: 90%;
  text-align: right;
}

@media screen and (max-width: 960px) {
  .resources-list {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

.resource-card {
  width: 90%;
  border: 1px black solid;
  min-width: 300px;
  margin-bottom: 10px;
  padding: 0.5em;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  margin-bottom: 1em;
}

#type-Input {
  width: 15em;
  border-radius: 10px;
}

.card-description{
  overflow-wrap: break-word;
}

.savedResourceFilterControls{
  margin: 15px;
}

#saved-resource-search{
  margin-right: 0px;
}
@media screen and (min-width: 960px) {
  .UserProfile {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    grid-column-start: 6;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}

@media screen and (max-width: 960px) {
  .UserProfile {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}


@media screen and (min-width: 960px) {
  .EditProfileForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3b3a50;
    color: #eeebd9;
    grid-column-start: 6;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}

@media screen and (max-width: 960px) {
  .EditProfileForm {
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3b3a50;
    color: #eeebd9;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
}


.ProfileRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.title {
  grid-column-start: 1;
  grid-column-end: 9;
  text-align: center;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 1;
  }
  5% {
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
    opacity: 1;
  }

  15% {
    -webkit-transform:translateY(0);
            transform:translateY(0);
    padding-bottom: 5px;
  }

  30% {
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }

  40% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 6px;
  }

  50% {
    -webkit-transform:translateY(-30%);
            transform:translateY(-30%);
  }

  70% {
      -webkit-transform:translateY(0%);
              transform:translateY(0%);
      padding-bottom: 7px;
  }
  80% {
    -webkit-transform:translateY(-15%);
            transform:translateY(-15%);
  }
  90% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    -webkit-transform:translateY(-7%);
            transform:translateY(-7%);
  }
  97% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    -webkit-transform:translateY(-3%);
            transform:translateY(-3%);
  }
  100% {
    -webkit-transform:translateY(0);
            transform:translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 1;
  }
  5% {
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
    opacity: 1;
  }

  15% {
    -webkit-transform:translateY(0);
            transform:translateY(0);
    padding-bottom: 5px;
  }

  30% {
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }

  40% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 6px;
  }

  50% {
    -webkit-transform:translateY(-30%);
            transform:translateY(-30%);
  }

  70% {
      -webkit-transform:translateY(0%);
              transform:translateY(0%);
      padding-bottom: 7px;
  }
  80% {
    -webkit-transform:translateY(-15%);
            transform:translateY(-15%);
  }
  90% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    -webkit-transform:translateY(-7%);
            transform:translateY(-7%);
  }
  97% {
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    -webkit-transform:translateY(-3%);
            transform:translateY(-3%);
  }
  100% {
    -webkit-transform:translateY(0);
            transform:translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

.wrapper {
  position: relative;
  background-image: url(/static/media/notFoundBackground.d3398369.jpg);
  height: 100%;
  min-height: 450px;
  width: 100%;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2em;

  grid-column-start: 1;
  grid-column-end: 9;
}

.description-title {
  margin: auto;
  text-align: center;
  border-radius: 10px 44px 79px 63px;
  border: 1px solid #ececec;
  width: 30%;
  background-color: rgb(35, 20, 78);

}
.description-title > h3 {
  margin-top: 40%;
  color: #ececec;
}

.description {
  text-align: center;
  margin-top: 10%;
  width: 20%;
  min-width: 250px;
  margin-left:auto;
  margin-right: auto;
  z-index: 2;
}

.description > p, h4 {
  color: #ececec
}

.backToDash:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#backToDash > img {
  width: 50px;
  height: 50px;
}
.astro {
  width: 100px;
  height: 100px;
  color: #ececec;
  position: relative;
  -webkit-transform: rotate(45);
          transform: rotate(45);
  float: right;
  margin-right: 10%;
  z-index: 1;
  -webkit-animation: bounce 5s;
          animation: bounce 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.credit {
  display: none;
}
.event-card {
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 0.75em auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  text-align: left;
  padding: 0.5em;
}

.myClassname {
  margin: 0 auto;
}

.event-controls button {
  border-radius: 10px;
  margin: 5px;
  background: none;
  color: #eeebd9;
  padding: 0.5em;
  border: 0.5px solid #eeebd9;
  margin: 5px auto;
}

.event-controls button:hover {
  background: #3c824e;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
@media screen and (min-width: 960px) {
  .events-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
}

  .results-container {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 18px;
    height: 500px;
    position: relative;
    text-align: center;
  }
  
  #go-back {
    position: absolute;
    left: 0;
    top: 0;
    color: #3a3846;
  }
  
  #go-back:hover {
    color: #fc766a;
  }
  
  .results > h2 {
    text-align: center;
  }
  
  .results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}

  @media screen and (max-width: 960px) {
    .events-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }  
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #3a3846;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }

  @media screen and (max-width: 480px) {
    .events-search-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 1em;
  }
  
  .results-container {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 9;
      border-radius: 18px;
      height: 400px;
      position: relative;
      text-align: center;
    }
    
    #go-back {
      position: absolute;
      left: 0;
      top: 0;
      color: #3a3846;
    }
    
    #go-back:hover {
      color: #fc766a;
    }
    
    .results > h2 {
      text-align: center;
    }
    
    .results {
      max-height: 100%;
      overflow-y: auto;
      overflow-block: inherit;
    }
  }
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.person-card {
  display: inline-block;
  background: #3a3846;
  width: 175px;
  color: white;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 10px;
}

.person-card:hover {
  background: #504e5a;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.person-contact-info {
  margin-left: 60%;
}
