.carousel__next-button, .carousel__back-button {
  background: rgb(141, 141, 167);
  border: none;
  color: white;
  height: 2.5em; 
}

.SummaryContainer{
  max-height: 350px;
  position: relative;
}

.carousel{
  max-height: 350px;
}

.summary-div{
  max-height: 350px;
}

.slideInner___2mfX9.carousel__inner-slide{
  height: 350px;
  padding-bottom: 0px;
}

.horizontalSlider___281Ls.carousel__slider.carousel__slider--horizontal{
  height: 350px;
  padding-bottom: 0px;
}

li.slide___3-Nqo.slideHorizontal___1NzNV.carousel__slide.carousel__slide--visible{
  height: 350px;
  padding-bottom: 0px;
} 

@media (min-width: 961px) {
  .SummaryContainer {
    grid-column-start: 4;
    grid-column-end: 9;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }

  .summary-div {
    height: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
  
  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(101, 101, 136);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    transform: scale(0.8);
  }
  
}

@media (min-width: 481px) and (max-width: 960px) {
  .sideNav {
    width: 200px;
  }
  .SummaryContainer {
    grid-column-start: 1;
    grid-column-end: 9;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }
  
  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(141, 141, 167);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    transform: scale(0.8);
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  .SummaryContainer {
    grid-column-start: 2;
    grid-column-end: 8;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    background: #3a3846;
    color: white;
  }

  .summary-div{
    width: 85%;
    margin: auto;
  }

  .carousel__next-button {
    border-radius: 10px;
    position: absolute;
    right: -20px;
    top: 50%;
  }
  
  .carousel__back-button {
    border-radius: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }
  
  .carousel__back-button:hover, .carousel__next-button:hover {
    background: rgb(141, 141, 167);
    border: none;
  }
  
  .carousel__back-button:active, .carousel__next-button:active {
    transform: scale(0.8);
  }
}





