.dashboard-page { 
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}
.dashboard-image > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}

.title {
  grid-column-start: 1;
  grid-column-end: 9;
  text-align: center;
}

@media (max-width: 960px) {
  .SideNav{
    display: none;
  }
}

@media (min-width: 960px) {
  .TopNav{
    display: none;
  }
}
