.saved-contact-list {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    height: 500px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow-y: auto;
}

.savedContactFilterControls {
    width: 90%;
    text-align: right; 
    margin: 10px;
}

#saved-contact-search{
    margin-right: 0px;
}

@media screen and (max-width: 960px) {
    .saved-contact-list {
        grid-column-start: 1;
        grid-column-end: 9;
    }
}