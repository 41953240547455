.green{
  background: rgb(102, 96, 194);
}

.yellow{
  background: #349caa;
}

.red{
  background: #e46f65;
}

.job-status{
  color: white;
}

.job-box{
  background: #3a3846;
    color: white;
}

@media screen and (min-width: 960px) {
  .job-box {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}

@media screen and (max-width: 960px) {
  .job-box {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 80%;
    border: 1px black solid;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}

@media screen and (max-width: 480px) {
  .job-box {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39); 
  }
  
  .edit-job-form {
    width: 80%;
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  }
  
  .job-status{
    border: 1px solid black;
    padding: 5px;
  }
  
  .job-box button{
    margin: 15px;
  }
  
  .job-description {
    width: 80%;
    margin: 0.5em auto;
    line-height: 168%;
    overflow-y: auto;
  }
  
  .job-url {
    width: 80%;
    margin: 0.5em auto;
  }
  
  .job-description > p {
    text-align: left;
  }
  
  .job-description > ul {
    text-align: left;
    margin-bottom: 1em;
  }
  
  .edit-job-form label, input{
    display: inline block;
    margin: 5px;
  }
  
  .edit-job-form button{
    margin: 15px;
  }
  
  .edit-job-form textarea{
    font-family: sans-serif;
  }
}




