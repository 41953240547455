* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css?family=Barlow|Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
}

p {font-size: 1em}

a {
  text-decoration: none;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(238, 238, 238);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --font-size: calc(10px + 1vmin);
}

.error-message{
  color: red;
  background: white;
}
