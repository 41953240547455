.JobSearchForm {
  width: 320px;
  border: 1px black solid;
  min-width: 300px;
  margin: 0 auto;
  padding: 5%;
  line-height: 150%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background: #3a3846;
  color: #eeebd9;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.jobSearchFormImage {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.jobSearchFormImage > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: -100px;
  left: -15%;
  z-index: -1;
}