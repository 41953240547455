.LoginRoute {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}
.login-image {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 9;
}

.login-image > img {
  height: 320px;
  width: 320px;
  position: absolute;
  top: 0;
  right: -10%;
  z-index: -1;
}

.new-user {
  grid-column-start: 3;
  grid-column-end: 7;
  text-align: center;
  color: #eeebd9;
}

@media screen and (max-width: 480px) {
  .new-user {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}