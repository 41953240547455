@media screen and (min-width: 961px) {
  .job-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
  }
  
  .job-results-container {
    position: relative;
    grid-column-start: 4;
    grid-column-end: 9;
    border-radius: 18px;
    height: 400px;
    position: relative;
    text-align: center;
  }

  .jobsRouteImage {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .jobsRouteImage > img {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -100px;
    right: -15%;
    z-index: -1;
  }
  
  #job-go-back {
    position: absolute;
    left: 0;
    top: 0;
    color:#3a3846;
  }
  
  #job-go-back:hover {
    color: #fc766a;
  }
  
  .job-results > h2 {
    text-align: center;
  }
  
  .job-results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}

@media screen and (max-width: 960px) {
  .job-search-results {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
  }

  .jobsRouteImage {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .jobsRouteImage > img {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -100px;
    right: -5%;
    z-index: -1;
  }
  
  .job-results-container {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
    border-radius: 18px;
    height: 400px;
    position: relative;
    text-align: center;
  }

  .topNav {
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  #job-go-back {
    position: absolute;
    left: 0;
    top: 0;
    color: #3a3846;
  }
  
  #job-go-back:hover {
    color: #fc766a;
  }
  
  .job-results > h2 {
    text-align: center;
  }
  
  .job-results {
    max-height: 100%;
    overflow-y: auto;
    overflow-block: inherit;
  }
}






