@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.TopNav {
  grid-column-start: 1;
  grid-column-end: 9;
  width: 100%;
  min-width: 320px;
  height: 130px;
  border-radius: 10px;
  margin: 2.5% auto;
  background: #3a3846;
  color: white;
  line-height: 168%;
  text-align: left;
  padding: 10px;
}

.topLink {
  display: inline-block;
  padding-left: 10px;
  width: 50%;
  font-family: 'Montserrat', sans-serif;
}

.topLink:active, .topLink:hover {
  transition: all 0.3s ease-in;
  background: #676572;
}

.topLink > a {
  font-size: 1em;
}

@media screen and (max-width: 380px) {
  .topLink {
    width: 50%;
    font-size: 14px;
  }
  .TopNav {
   min-width: 300px;
   margin: auto;
   width: 95%;
  }

}