

.Input {
  border-radius: 10px;
  width: 15em;
  height: 2em;
}

.Input:focus {
  transition: 0.3s;
  transform: scale(1.2);
  outline: none;
}