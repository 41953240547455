.company-box {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    padding: 0.5em;
    border-radius: 10px;
    margin: 0.75em auto;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-company-form {
    width: 90%;
    border: 1px black solid;
    min-width: 300px;
    margin: 0 auto;
    padding: 5%;
    line-height: 150%;
    border-radius: 10px;
    text-align: center;
    background: #3a3846;
    color: white;
    box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
}

.edit-company-form button{
    margin: 10px 5px;
}