.Button { 
  background: rgb(212, 212, 212);
  border: none;
  color: black;
  border-radius: 10px;
  margin: 5px;
  width: 10em;
  height: 2em;
  font-size: 14px;
}

.Button:hover {
  background: rgb(141, 141, 167);
}

.Button:focus {
  outline: none;
}