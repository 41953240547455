.event-card {
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 0.75em auto;
  background: #3a3846;
  color: white;
  box-shadow:  0.5em 0.5em 0.2em rgb(48, 39, 39);
  text-align: left;
  padding: 0.5em;
}

.myClassname {
  margin: 0 auto;
}

.event-controls button {
  border-radius: 10px;
  margin: 5px;
  background: none;
  color: #eeebd9;
  padding: 0.5em;
  border: 0.5px solid #eeebd9;
  margin: 5px auto;
}

.event-controls button:hover {
  background: #3c824e;
  transition: 0.3s all;
}